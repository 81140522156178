import { CONSUMPTION, PRICE } from '@utils/constants/category-types';
import { perc } from '../Consumption.utils';
import { createConsumptionDisabledBar } from './disabled-bar';
import { generateBarsProps, generateBarsReturn } from './generate-bars';

export const baseBar = (props: generateBarsProps): generateBarsReturn => {
  const { category, node, max } = props;
  const { base, subscription } = node;
  let sum = 0;

  if (category === PRICE) {
    sum = base + subscription;

    return {
      percentages: {
        base: perc(base, sum),
        subscription: perc(subscription, sum),
      },
      totalPercentage: perc(sum, max),
    };
  }

  if (category === CONSUMPTION) {
    sum = subscription;

    if (base === 0) {
      return createConsumptionDisabledBar();
    }

    return {
      percentages: {
        base: 100,
      },
      totalPercentage: perc(base, max),
    };
  }

  // Default:
  return {
    percentages: {},
    totalPercentage: 0,
  };
};
